import React from 'react'
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import AboutUs from '../Components/AboutUs';
import OurServices from '../Components/OurServices';
import WhyChooseUs from '../Components/WhyChooseUs';
import OurTeam from '../Components/OurTeam';
import ClientTestimonal from '../Components/ClientTestimonal';
import NewsLetter from '../Components/NewsLetter';
import Footer from '../Components/Footer';


const IndexPage = () => {
    return (
        <div>

            <Navbar />
            <Header />
            <OurServices />
            <AboutUs />
            <WhyChooseUs />
            <OurTeam />
            <NewsLetter />
            <Footer />
        </div>
    )
}

export default IndexPage