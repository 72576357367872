import './App.css';
import IndexPage from './Pages/IndexPage';
import AboutUsPage from './Pages/AboutUsPage';
import ServicesPage from './Pages/ServicesPage';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactPage from './Pages/ContactPage';
import OurTeamPage from './Pages/OurTeamPage';
import ScrollTop from './Components/ScrollTop';



function App() {
  return (
    <div className="App">
      <Router>
        <ScrollTop />
        <Routes>
          <Route exact path="" element={<IndexPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/service" element={<ServicesPage />} />
          <Route path="/ourteam" element={<OurTeamPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/*" element={<IndexPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
